import api from '../../../globals/client';
import config from '../../../config/app.config';

var promise;

export default class FacilityDashboardService {
    async reservationList(data) {
        promise = await api.client.get(config.end_point.facility.reservations.list, {params: data});
        return promise;
    }

    async parkingList() {
        promise =  api.client.get(config.end_point.facility.parkings +`?paginate=1&per_page=10&airport_id=${config.airport_id}`);
        return promise;
    }

    async getReserved() {
        promise =  api.client.get(config.end_point.facility.reserve,{parking_id: 1});
        return promise;
    }
}

export const facilityDashboardService = new FacilityDashboardService();
