import {facilityDashboardService} from "@/services/facility/dashboard/dashboard.service";
import '@fullcalendar/core/vdom' // solves problem with Vite
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import {alertService} from "@/services/alert/alert.service";
import allLocales from '@fullcalendar/core/locales-all';
import { EventBus } from "@/event-bus";

export default {
    components: {
        FullCalendar // make the <FullCalendar> tag available
    },
    data () {
        return {
            data: {},
            filter: {
                page: 10,
            },
            calendarOptions: {
                locales: allLocales,
                locale: this.$i18n.locale,
                plugins: [ dayGridPlugin, interactionPlugin ],
                initialView: 'dayGridMonth',
                selectable: false,
                selectOverlap: false,
                eventOverlap: false,
            },
            calendarData: [],
            stopBooking: []
        }
    },
    async mounted() {
        this.list();

        EventBus.$on('changeLocal', ()=>{
            this.calendarData.forEach(item => {
                item.locale = this.$i18n.locale;
            });

            this.calendarOptions.locale = this.$i18n.locale;
        });
    },
    methods: {
        async list() {
            alertService.loading();
            await facilityDashboardService.parkingList()
            .then((response) => {
                this.data = response.data;

                let events = [];
                response.data.data.forEach(inventory => {

                    inventory.reservable_capacities.forEach(capacity => {
                        events.push({
                            start: capacity.date,
                            end : capacity.date,
                            title: capacity.capacity,
                            parkingId: inventory.id,
                            reserved: 0,
                        })
                    });

                    inventory.stop_booking.forEach(stop_booking => {
                        events.push({
                            start: stop_booking.date,
                            end : stop_booking.date,
                            title: stop_booking.capacity,
                            parkingId: inventory.id,
                            reserved: 0,
                        })

                        this.stopBooking.push({date: stop_booking.date,parking_id: inventory.id});    
                    });

                    this.setReserved(events,inventory.id);
                    
                    events = [];
                });

            })

        },
        async setReserved(events,id){

            facilityDashboardService.getReserved()
                .then((response)=> {
                    if(typeof response.data[id] !== 'undefined') {
                        response.data[id].forEach(reserve => {
                            events.forEach(event => {
                                if(reserve.date == event.start) {
                                    event.reserved = reserve.total;
                                }
                            });
                        });
                    }
            });
            this.calendarData.push({events,...this.calendarOptions, ...{eventContent: this.reRenderCalendar}})
            this.$emit('calendar', !this.calendarData.length);
            alertService.close();
        },

        reRenderCalendar(arg){

            this.stopBooking.forEach( list => {
                if(list.parking_id == arg.event.extendedProps.parkingId && list.date == arg.event.startStr){
                    $(`.parking-id-${list.parking_id}`).find(`[data-date='${arg.event.startStr}']`).css({"background":"#ffcccb"});
                }
            });

            return  { html:`<strong><span :style="color: ${arg.event.textColor} !important" class="text-primary event-value">${arg.event.extendedProps.reserved}</span>${arg.event.title}</strong>`}
        },
    }

}
