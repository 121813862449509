import {facilityDashboardService} from "@/services/facility/dashboard/dashboard.service";
import PaginateComponent from "@/resources/components/paginate.component";
import Inventory from './inventory.component.vue';
import {alertService} from "@/services/alert/alert.service";

export default {
    components: {PaginateComponent,Inventory},
    data: () => {
        return {
            data: {},
            dataReady: false,
            filter: {
                paginate : 1,
                per_page: 10
            },
            InventoryData : true        
        }
    },
    async mounted() {
        this.list(this.filter, {page: 1});
    },
    methods: {
        hasData(data){
            this.InventoryData = data;
        },
        async search$(data) {
            this.list(this.filter, data)
        },

        async list(query, data) {

            this.dataReady = false
            alertService.loading();
            await facilityDashboardService.reservationList({...query, ...data}).then((response) => {
                this.data = response.data
                this.dataReady = true
                alertService.close();
            }).catch((error) => {
                alertService.close();
                console.log(error)
            });

        },

    }

}
